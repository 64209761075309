import React from "react";
import { graphql } from 'gatsby'

import Layout from "../components/layout"
import SEO from "../components/seo"
import SidebarRecentPosts from "../components/sidebar-recent-posts";
import RichText from "../components/rich-text"
import Image from "../components/image"
import SimpleForm from "../components/form-simple-ajax"

const Contacts = ({data}) => (
    <Layout page='contacts'>
    <SEO title="Contacts" />
      <div className="container container-flex">
        <main role="main">
          <Image fluid={data.contact.cover.fluid} file={data.contact.cover.file} alt="some text" classStyle="post-image" />
          <h2>{data.contact.title}</h2>
          <RichText text={data.contact.content.json}  />
          <SimpleForm />
        </main>

        <aside className="sidebar">
        
          <SidebarRecentPosts />
        </aside>
      </div>
    </Layout>
  );

export default Contacts;

export const contactsQuery = graphql`
 query contacts {
  contact: contentfulSimpleLife(slug: {eq: "contacts"}) {
    slug
    title
    content {
      json
    }
    cover {
      file {
            url
            fileName
            contentType
            details {
              image {
                height
                width
              }
              size
            }
          }
      fluid {
        aspectRatio
        sizes
        src
        srcSet
      }
    }
  }
}

`

