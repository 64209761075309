import React, { Fragment, useState } from 'react'
import Helmet from 'react-helmet'
import emailjs from "emailjs-com";

import './form.css'

const SimpleForm = () => {
  const defaultProps = {
    name: 'Simple Form Ajax',
    subject: 'Site message from ', // optional subject of the notification email
    action: '',
    successMessage: 'Grazie per la richiesta, ti risponderemo prima possibile.',
    errorMessage:
      "C'è un problema, il tuo messaggio non è stato inviato. Per favore prova a contattarci via email."
  }

  const form = {firstname:'', lastname: '', emailAddress: '', message:''}

  const [alert, setAlert] = useState('');
  const [disabled, setDisabled] = useState(false);
  
  const changeHandler = e => {
    form[e.target.name] = e.target.value
  }

  const sendMessage = e => {
    e.preventDefault()
    if (disabled) return
    const formHtml = e.target
    var templateParams = {
      subject: defaultProps.subject,
      from_name: form.firstname + " " + form.lastname,
      to_name: "Webmaster",
      message_html:
        "<strong>Email.. : </strong>" + form.emailAddress + "<br />" +
        "<strong>Message : </strong>" + form.message + "<br />"
    };
    emailjs.init("user_LP74lmdFsHosCW1ky2DAx");
    emailjs
      .send("mailgun", "template_N2qPn4Oo", templateParams)
      .then(res => {
        if (res.status === 200) {
          return res
        } else {
          throw new Error('Network error')
        }
      })
      .then(() => {
        formHtml.reset()
        setAlert(defaultProps.successMessage)
        setDisabled(false)
      })
      .catch(error => {
        setAlert(defaultProps.errorMessage)
        setDisabled(false)
      })
  };

    const { name, subject, action } = defaultProps

    return (
      <Fragment>
        <Helmet>
          <script src="https://www.google.com/recaptcha/api.js" />
        </Helmet>
        <form
          className="Form"
          name={name}
          action={action}
          onSubmit={sendMessage}
          data-netlify=""
          netlify-recaptcha=""
        >
          {alert && (
            <div className="Form--Alert">{alert}</div>
          )}
          <div className="Form--Group">
            <label className="Form--Label">
              <input
                className="Form--Input Form--InputText"
                type="text"
                placeholder="Nome"
                name="firstname"
                required
                onChange={changeHandler}
              />
              <span>Nome</span>
            </label>
            <label className="Form--Label">
              <input
                className="Form--Input Form--InputText"
                type="text"
                placeholder="Cognome"
                name="lastname"
                required
                onChange={changeHandler}
              />
              <span>Cognome</span>
            </label>
          </div>

          <label className="Form--Label">
            <input
              className="Form--Input Form--InputText"
              type="email"
              placeholder="Email"
              name="emailAddress"
              required
              onChange={changeHandler}
            />
            <span>Indirizzo email</span>
          </label>
          
          <label className="Form--Label">
            <textarea
              className="Form--Input Form--Textarea Form--InputText"
              placeholder="Messaggio"
              name="message"
              rows="10"
              required
              onChange={changeHandler}
            />
            <span>Messaggio</span>
          </label>
          <label className="Form--Label Form-Checkbox">
            <input
              className="Form--Input Form--Textarea Form--CheckboxInput"
              name="newsletter"
              type="checkbox"
            />
           
          </label>
          
          {!!subject && <input type="hidden" name="subject" value={subject} />}
          <input type="hidden" name="form-name" value={name} />
          <input
            className="Button Form--SubmitButton"
            type="submit"
            value="Invia"
            disabled={disabled}
          />
        </form>
      </Fragment>
    )
}

export default SimpleForm
